import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/SEO'

import contactStyles from '../css/contact.module.css'
import fb from '../images/facebook-contact.svg'
import map from '../images/map.png'

const ContactPage = () => (
	<Layout>
		<SEO title="Contact" />
		<h1>Contact</h1>
		<address className={contactStyles.contactInfo}>
			<p className={contactStyles.contactLine}>Elke De Wispelaere</p>
			<p className={contactStyles.contactLine}>0499/33.68.74</p>
			<a
				href="mailto:kinepluskids@gmail.com"
				className={contactStyles.contactLine}
				style={{ textDecoration: `underline`, color: `#021841` }}
			>
				kinepluskids@gmail.com
			</a>
		</address>
		<p
			className={contactStyles.contactLine}
			style={{ marginTop: '0.8rem' }}
		>
			Ondernemingsnummer: 0681.549.813
		</p>
		<div className={contactStyles.fbContainer}>
			<a
				href="https://www.facebook.com/kinepluskids/"
				target="_blank"
				rel="noopener noreferrer"
				className={contactStyles.fb}
			>
				<img src={fb} alt="facebook logo" width="85" height="85"></img>
			</a>
			<p className={contactStyles.fbInfo}>
				Volg ons zeker ook op de Facebookpagina Kine+ Kids. Hier vind je
				leuke tips en weetjes, interessante artikels en alles over het
				doen en laten in de praktijk.
			</p>
		</div>
		<div className={contactStyles.mapContainer}>
			<a
				href="https://goo.gl/maps/rgUwa3ai9ZB2"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					className={contactStyles.map}
					src={map}
					alt="location map"
					width="600"
					height="400"
				></img>
			</a>
			<address className={contactStyles.contactInfo}>
				<p
					className={
						contactStyles.adresLine +
						' ' +
						contactStyles.contactLine
					}
				>
					Hoogstraat 109 B 8780 Oostrozebeke
				</p>
				<p>
					Gelieve aan te bellen aan de parlofoon in de centrale
					ingang.
				</p>
			</address>
		</div>
		<div className={contactStyles.peetje}>
			<p>
				Gelieve bij het maken of annuleren van een afspraak steeds
				telefonisch contact op te nemen. Indien de therapie minder dan
				24 uur op voorhand wordt geannuleerd wordt de sessie
				aangerekend. Gelieve tijdig te verwittigen.
			</p>
			<br></br>
			<p>
				Kinesitherapie wordt nog steeds voorgeschreven door een dokter
				of specialist. U heeft een voorschrift nodig alvorens therapie
				te kunnen volgen.
			</p>
			<br></br>
			<p>
				<span className="fat">
					Kine+ Kids is een geconventioneerde praktijk.
				</span>{' '}
				Wij gebruiken de tarieven en terugbetaling die het RIZIV
				hanteert.
			</p>
			<br></br>
			<p>
				In samenspraak kan de behandeling worden uitgevoerd in de praktijk, een crèche, in school of aan huis in de gemeentes Desselgem, Ooigem, Izegem, Ingelmunster, Wielsbeke, Waregem, Zulte, Dentergem, Tielt, Wakken, Meulebeke, Marialoop, Sint-Eloois-Vijve, Sint-Baafs-Vijve, Harelbeke, Beveren-Leie, Deerlijk, Lendelede,...
			</p>
		</div>
	</Layout>
)

export default ContactPage
